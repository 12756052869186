<template>
	<div class="layout">
		<!-- Top nav bar -->
		<navbar />
		<!-- Content -->
		<div class="container-fluid">
			<div class="row">
				<!-- Sidebar -->
				<sidebar>
				</sidebar>
				<!-- Main content old css col-md-9 col-lg-10 ml-sm-0 col-xl-10 px-4 mr-xl-auto -->
				<main role="main" class="col-md-9 col-lg-9 col-xl-10 ml-sm-0 10 px-4 mr-xl-auto pb-5">
					<router-view/>
					<page-footer />
				</main>
			</div>
		</div>
	</div>
</template>

<script>
	import Sidebar from "@/components/navigation/Sidebar";
	import Navbar from "@/components/navigation/Navbar";
	import PageFooter from "@/components/navigation/PageFooter";
	export default {
		name: "PlainWithSidebar",
		components: {PageFooter, Navbar, Sidebar}
	};
</script>

<style scoped>

</style>
